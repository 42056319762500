import React from "react";
import Modal from "react-modal";
import { cancelRegistration } from "../../../registration/RegistrationUtility";

class AdminParticipantFormCancel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attemptActivationCode: '',
      cancelModalOpen: false,
      error: ''
    }
    this.attemptActivation = this.attemptActivation.bind(this);
    this.toggleCancelModal = this.toggleCancelModal.bind(this);
  }

  toggleCancelModal() {
    const { cancelModalOpen } = this.state;
    this.setState({
      cancelModalOpen: !cancelModalOpen
    });
  }

  cancelParticipant() {

  }

  attemptActivation() {
    const { formikProps, toggleModal, user_email, updateParticipantCallback } = this.props;
    const { attemptActivationCode } = this.state;
    this.setState({
      error: null
    })
    var ids = [
      '4191',
      '9553',
    ]
    if(ids.indexOf(attemptActivationCode) != -1) {
      cancelRegistration(formikProps.values.participant.email, user_email, '/registration/cancel', this, (json) => {
        updateParticipantCallback(json.participants);
        toggleModal();
      });
    }else {
      this.setState({
        error: "Code not found. Please try another."
      })
    }
  }

  renderModal() {
    const { formikProps } = this.props;
    const { attemptActivationCode, error } = this.state;
    return (
      <div className="sg-admin-ticketing-activation-form" onClick={(e)=>{e.stopPropagation()}}>
        <label> Please Input Access Code to Cancel Registration for:</label>
        <br />
        {formikProps.values.participant.email}
        <br />
        <br />
        <input
          value={attemptActivationCode}
          onChange={(e)=>this.setState({
            attemptActivationCode: e.target.value
          })}
          className="form-control"
        />
        <br/>
        <button
          onClick={this.attemptActivation}
          className="sg-button sg-submit-button"
          style={{width: "auto"}}
        > Submit </button>
        {error ? <div className="error">{error}</div>:""}
      </div>
    )
  }

  render() {
    const { question, toggleModal } = this.props;
    const { cancelModalOpen } = this.state;

    return (
      <>
        <div onClick={this.toggleCancelModal}>
          <button className="sg-button sg-cancel-button reg-button">
            {question.label}
          </button>
        </div>
        <Modal
          className="white-background cnt-user-admin-modal"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={cancelModalOpen}
          onRequestClose={this.toggleCancelModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default AdminParticipantFormCancel;
