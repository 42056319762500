import React from "react";
import { Formik, Field, Form } from "formik";
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import { submitSurvey } from "./SurveyUtility";
import { getOrderedPages, getOrderedQuestions, splitObjectField, getFormikSurveyValue, setFormikSurveyValue, isChildEnabled, translateCSS } from "../utility/registration/RegFormUtility";

class SurveyForm extends React.Component {
  constructor(props) {
    super(props);
    let surveyForm = props.form;

    this.state = {
      surveyForm: surveyForm,
      regPages: getOrderedPages(surveyForm),
      page: 0,
      checkError: false,
      fullPageError: '',
      emailTaken: false,
      saving: false,
      participant: props.participant
    }
  }

  pageHiddenClass(renderedPage) {
    const { page } = this.state;
    if(renderedPage == page) {
      return "visible";
    }
    return "hidden";
  }

  renderConfirmationPage() {
    const { participant } = this.props;
    return (
      <div className="registration-page-one container">
        <div className="row">
          <div className="col-xs-12 registration-section-copy" style={{color: "#000000", textAlign: "center"}}>
            <br/>
            Thank you for taking the survey.
            <br />
            <br />
            If you have any questions, please contact the Events Team.
            <br />
            <br />
            <div className="text-center">
              <a target="_blank" href="mailto:donotemail@jaguardesignstudio.com">
                <button type="button" style={{paddingTop: "10px", marginBottom: "20px"}} className="sg-button sg-submit-button">
                  Contact Us
                </button>
              </a>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }

  submitFormButtonOnClick(formikProps) {
    if(this.isCurrentPageValid(formikProps)) {
      this.setState({
        checkError: true,
        fullPageError: '',
      })
      formikProps.handleSubmit();
    } else {
      this.setState({
        fullPageError: "Please make sure all fields have been properly filled in.",
        checkError: true,
      });
    }
  }

  renderNextButton(question, formikProps) {
    return (
      <div className={`col-xs-12 col-md-3 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        <div onClick={() => this.nextPage(formikProps)}>
          <button className="sg-button sg-submit-button reg-button">
            {question.label}
          </button>
        </div>
      </div>
    );
  }

  renderPreviousButton(question, formikProps) {
    return (
      <div className={`col-xs-12 col-md-3 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        <div onClick={() => this.prevPage(formikProps)}>
          <button className="sg-button sg-submit-button reg-button">
            {question.label}
          </button>
        </div>
      </div>
    );
  }

  renderSubmitButton(question, formikProps) {
    return (
      <div className={`col-xs-12 col-md-3 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        <div onClick={() => this.submitFormButtonOnClick(formikProps)}>
          <button className="sg-button sg-submit-button reg-button">
            {question.label}
          </button>
        </div>
      </div>
    );
  }

  renderOtherButton(question, formikProps) {
    return (
      <div className={`col-xs-12 col-md-3 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        <button className="sg-button sg-submit-button reg-button" onClick={() => {location.href='mailto:executiverelations@cisco.com?subject=Event%20Feedback%20%7C%202024%20AT%26T%20Pro-Am'}}>
          {question.label}
        </button>
      </div>
    );
  }

  renderButton(question, formikProps) {
    let renderedButton = "";
    switch(question.properties.function_type) {
      case 'next_page':
        renderedButton = this.renderNextButton(question, formikProps);
        break;
      case 'previous_page':
        renderedButton = this.renderPreviousButton(question, formikProps);
        break;
      case 'submit':
        renderedButton = this.renderSubmitButton(question, formikProps);
        break;
      case 'other':
        renderedButton = this.renderOtherButton(question, formikProps);
        break;
      default:
        return <></>;
    }
    return renderedButton;
  }

  isFieldValid(question, formikProps) {
    const splitQuestionId = splitObjectField(question.attribute_id);
    if (formikProps.values['survey'][splitQuestionId[0]] === undefined) return false;
    if (formikProps.values['survey'][splitQuestionId[0]].length < 1 ) return false;
    return true;
  }

  isCurrentPageValid(formikProps) {
    const { page, regPages }  = this.state;
    const currentPage = regPages[page];
    let isValid = true;
    currentPage.questions.forEach( question => {
      if(this.isFieldEnabled(question, formikProps)) {
        if (question.required) {
          if (!this.isFieldValid(question, formikProps)) {
            isValid = false;
          }
        }
      }
    });
    return isValid;
  }

  nextPage(formikProps = {}){
    const { page }  = this.state;
    if(this.isCurrentPageValid(formikProps)) {
      window.scrollTo(0,0);
      this.setState({
        page: page + 1,
        checkError: false,
      })
    } else {
      this.setState({
        fullPageError: "Please make sure all fields have been properly filled in.",
        checkError: true,
      });
    }
  }

  prevPage(formikProps){
    const { page }  = this.state;
    window.scrollTo(0,0);
    this.setState({
      page: page - 1,
      fullPageError: null,
      checkError: false
    })
  }

  renderQuestionLabel(question) {
    return (
      <div className="label-wrapper">
        <label className={`label-english ${question.required ? 'required':''}`}> {question.label} </label>
      </div>
    );
  }

  renderCheckboxField(question, formikProps) {
    const { checkError } = this.state;
    let erroring = checkError && !this.isFieldValid(question, formikProps);
    if(!question.required) {
      erroring = false;
    }
    let value = getFormikSurveyValue(question.attribute_id, formikProps);

    let checkboxOptions = question.options.map(option =>
      <div className={`col-xs-12 col-md-3 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        <div className="radio-option-wrapper">
          <img
            src={ value !== undefined && value.includes(option.value) ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
            style={{cursor:"pointer", marginBottom: "7px"}}
            onClick={(e) => {
              if (value) {
                let index = value.indexOf(option.value);
                if (index !== -1) {
                  value.splice(index, 1);
                } else {
                  value.push(option.value);
                }
                setFormikSurveyValue(value, question.attribute_id, formikProps);
              } else {
                setFormikSurveyValue([option.value], question.attribute_id, formikProps);
              }
            }}
          />
          <div className="radio-option-label">{option.label}</div>
        </div>
      </div>
    )

    return(
      <>
        <div className={`col-xs-12 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
          {this.renderQuestionLabel(question)}
        </div>
        {checkboxOptions}
        <div className={`col-xs-12`} />
        {
          erroring ?
          <div className="col-xs-12">
            <div className="registration-field-error">
              <>Please select an option to continue.</>
            </div>
          </div>
          :
          <></>
        }
      </>
    )
  }

  renderDateField(question, formikProps) {
    const { checkError } = this.state;
    let erroring = checkError && !this.isFieldValid(question, formikProps);
    if(!question.required) {
      erroring = false;
    }
    return (
      <div className={`col-xs-12 col-sm-6 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        {this.renderQuestionLabel(question)}
        <DatePicker
          name={`survey[${question.attribute_id}]`}
          selected={getFormikSurveyValue(question.attribute_id, formikProps)}
          onChange={value => {
            setFormikSurveyValue(value, question.attribute_id, formikProps);
          }}
          minDate={question.properties.minimum_date ? new Date(question.properties.minimum_date) : ''}
          maxDate={question.properties.maximum_date ? new Date(question.properties.maximum_date) : ''}
          className={`form-control ${erroring ? 'field-erroring': "" }`}
        />
      </div>
    )
  }

  renderDivider(question, formikprops) {
    return (
      <div className="col-xs-12">
        <div className="content-divider"/>
      </div>
    );
  }

  renderParagraph(question, formikProps) {
    return (
      <div className={`col-xs-12 registration-section-copy ${question.custom_class}`} style={translateCSS(question.custom_css)} dangerouslySetInnerHTML={{__html: question.content}} />
    );
  }

  renderRadioField(question, formikProps) {
    const { checkError } = this.state;
    let erroring = checkError && !this.isFieldValid(question, formikProps);
    if(!question.required) {
      erroring = false;
    }
    let value = getFormikSurveyValue(question.attribute_id, formikProps);
    let radioOptions = question.options.map(option =>
      <div className={`col-xs-12 col-md-3 ${question.custom_class} radio-field`} style={translateCSS(question.custom_css)}>
        <div className="radio-option-wrapper">
          <img
            src={ value === option.value ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
            style={{cursor:"pointer", marginBottom: "7px"}}
            onClick={() => {
              setFormikSurveyValue(option.value, question.attribute_id, formikProps);
            }}
          />
          <div className="radio-option-label">{option.label}</div>
        </div>
      </div>
    )

    // return(
    //   <>
    //     <div
    //       className={`radio-label-wrapper col-xs-12 ${question.custom_class.includes('radio-block-table') ? 'radio-block-table-label' : question.custom_class}`}
    //       style={translateCSS(question.custom_css)}
    //     >
    //       {this.renderQuestionLabel(question)}
    //     </div>
    //     {radioOptions}
    //     <div className={`col-xs-12`} />
    //     {
    //       erroring ?
    //       <div className="col-xs-12">
    //         <div className="registration-field-error">
    //           <>Please select an option to continue.</>
    //         </div>
    //       </div>
    //       :
    //       <></>
    //     }
    //   </>
    // );

    return(
      <>
        <div className="radio-label-wrapper">
          <div
            className={`col-xs-12 ${question.custom_class.includes('radio-block-table') ? 'radio-block-table-label' : question.custom_class}`}
            style={translateCSS(question.custom_css)}
          >
            {this.renderQuestionLabel(question)}
          </div>
        </div>
        {radioOptions}
        <div className={`col-xs-12`} />
        {
          erroring ?
          <div className="col-xs-12">
            <div className="registration-field-error">
              <>Please select an option to continue.</>
            </div>
          </div>
          :
          <></>
        }
      </>
    );
  }

  // renderRadioField(question, formikProps) {
  //   const { checkError } = this.state;
  //   let erroring = checkError && !this.isFieldValid(question, formikProps);
  //   if(!question.required) {
  //     erroring = false;
  //   }
  //   let value = getFormikSurveyValue(question.attribute_id, formikProps);

  //   let radioOptions = question.options.map(option =>
  //     <div className={`col-xs-12 col-md-3 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
  //       <div className="radio-option-wrapper">
  //         <img
  //           src={ value === option.value ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
  //           style={{cursor:"pointer", marginBottom: "7px"}}
  //           onClick={() => {
  //             setFormikSurveyValue(option.value, question.attribute_id, formikProps);
  //           }}
  //         />
  //         <div className="radio-option-label">{option.label}</div>
  //       </div>
  //     </div>
  //   )

  //   return(
  //     <>
  //       <div className={`col-xs-12`} style={translateCSS(question.custom_css)}>
  //         {this.renderQuestionLabel(question)}
  //       </div>
  //       {radioOptions}
  //       <div className={`col-xs-12`} />
  //       {
  //         erroring ?
  //         <div className="col-xs-12">
  //           <div className="registration-field-error">
  //             <>Please select an option to continue.</>
  //           </div>
  //         </div>
  //         :
  //         <></>
  //       }
  //     </>
  //   )
  // }

  renderSelectField(question, formikProps) {
    const { checkError } = this.state;
    let erroring = checkError && !this.isFieldValid(question, formikProps);
    if(!question.required) {
      erroring = false;
    }
    let value = getFormikSurveyValue(question.attribute_id, formikProps);

    return (
      <div className={`col-xs-12 col-sm-6 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        {this.renderQuestionLabel(question)}
        <Select
          options={question.options}
          classNamePrefix='registration-select'
          value={question.options.find(option => value === option.value)}
          onChange={selected => {
            setFormikSurveyValue(selected.value, question.attribute_id, formikProps);
          }}
          className={erroring ? "registration-select-erroring" : ""}
        />
      </div>
    )
  }

  renderTextField(question, formikProps) {
    const { checkError } = this.state;
    let erroring = checkError && !this.isFieldValid(question, formikProps);
    if(!question.required) {
      erroring = false;
    }
    return (
      <div className={`col-xs-12 col-sm-6 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        {this.renderQuestionLabel(question)}
        <Field className={`form-control ${erroring ? 'field-erroring': "" }`} name={`survey[${question.attribute_id}]`} disabled={question.disabled} />
      </div>
    )
  }

  renderTextArea(question, formikProps) {
    const { checkError } = this.state;
    let erroring = checkError && !this.isFieldValid(question, formikProps);
    if(!question.required) {
      erroring = false;
    }
    return (
      <div className={`col-xs-12 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        {this.renderQuestionLabel(question)}
        <Field className={`form-control ${erroring ? 'field-erroring': "" }`} name={`survey[${question.attribute_id}]`} as="textarea" />
      </div>
    )
  }

  isFieldEnabled(question, formikProps) {
    let isEnabled = true;
    if (question.use_parent_child) {
      isEnabled = isChildEnabled(question, formikProps)
    }
    if (question.hidden) {
      isEnabled = false;
    }
    return isEnabled;
  }

  renderPage(formPage, formikProps) {
    const regQuestions = getOrderedQuestions(formPage);
    const renderedQuestions = regQuestions.map(question => {
      if(this.isFieldEnabled(question, formikProps)) {
        let renderedQuestion = "";
        switch (question.question_type) {
          case "button":
            renderedQuestion = this.renderButton(question, formikProps);
            break;
          case "checkbox":
            renderedQuestion = this.renderCheckboxField(question, formikProps);
            break;
          case "date":
            renderedQuestion = this.renderDateField(question, formikProps);
            break;
          case "divider":
            renderedQuestion = this.renderDivider(question, formikProps);
            break;
          case "paragraph":
            renderedQuestion = this.renderParagraph(question, formikProps);
            break;
          case "radio":
            renderedQuestion = this.renderRadioField(question, formikProps);
            break;
          case "select":
            renderedQuestion = this.renderSelectField(question, formikProps);
            break;
          case "text":
            renderedQuestion = this.renderTextField(question, formikProps);
            break;
          case "textarea":
            renderedQuestion = this.renderTextArea(question, formikProps);
            break;
          default:
            return <></>;
        }
        return (
          <>{renderedQuestion}</>
        )
      }
      return <></>;
    });

    return (
      <>
        <div className="container">
          <div className="row">
            {renderedQuestions}
            {this.renderFullPageError(formikProps)}
          </div>
        </div>
      </>
    )
  }

  renderFullPageError(formikProps) {
    const { checkError, fullPageError } = this.state;
    if (checkError && fullPageError != '' && !this.isCurrentPageValid(formikProps)) {
      return (
        <div className="col-xs-12 text-center">
          <div className="registration-field-error text-center">
            {fullPageError}
          </div>
        </div>
      )
    }
    return <></>;
  }

  renderPages(formikProps) {
    const { surveyForm, page, regPages } = this.state;
    if (regPages.length > page) {
      // Survey Pages
      return this.renderPage(regPages[page], formikProps);
    } else if (regPages.length === page) {
      // Confirmation Page
      return this.renderConfirmationPage()
    } else {
      // Page out of bounds
      return <>Page out of index</>
    }
  }

  getInitialValues() {
    const { regPages } = this.state;
    let initialValues = {
      survey: {}
    };
    regPages.forEach(page => {
      page.questions.forEach(question => {
        if (question.default_value !== '') {
          const splitQuestionId = splitObjectField(question.attribute_id);
          initialValues['survey'][splitQuestionId[0]] = question.default_value;
        }
      });
    });
    return initialValues;
  }

  render() {
    const { form } = this.props;
    return (
      <div className="registration-form">
        <Formik
          initialValues={this.getInitialValues()}
          onSubmit={(values, actions) => {
            submitSurvey(values, this.state, this, form.gid)
          }}
          render={(formikProps) => (
            <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="user-registration-form" style={{border: "0", paddingBottom: "20px"}}>
              {this.renderPages(formikProps)}
              {/* {this.renderButtons(formikProps)} */}
            </Form>
        )}/>
      </div>
    );
  }
}

export default SurveyForm;
