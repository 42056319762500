import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import ReactTooltip from "react-tooltip";
import Cookies from "universal-cookie";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
import { canSessionBeAdded, isInTrack } from "@utility/SessionUtils"
import Table from "rc-table";
import "chart.js";
import { LineChart, AreaChart, ColumnChart, BarChart } from "react-chartkick";
import GeneralTable from "./GeneralTable";
import AdminOpenablePanel from "./platform_reports/AdminOpenablePanel";
import ReportBubble from "./platform_reports/ReportBubble"
import RegisteredOverTimeModal from "./platform_reports/RegisteredOverTimeModal"
import DefaultTooltip from "@layout/tooltips/DefaultTooltip"
import MailActivityReports from "./platform_reports/mail/MailActivityReports"
import EmailSectionStats from "./platform_reports/mail/EmailSectionStats"
import EmailSection from "./platform_reports/EmailSection"
import AddAdminSection from "./admins/AddAdminSection"
import TicketsDashboard from "./ticketing/TicketsDashboard"
import { fetchAPI } from "@utility/NetworkUtils"
import CustomReportTable from "./platform_reports/CustomReportTable"
import AdminParticipantModal from "./ticketing/forms/AdminParticipantModal"
import ParticipantTicketTable from "./ticketing/ParticipantTicketTable"
import FlipFlopFeatures from "./FlipFlopFeatures";

class UsersDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      googleDwellJson: null,
      ticketTypes: props.ticket_info,
      participants:  props.participants
    }
    this.updateTicket = this.updateTicket.bind(this);
    this.updateAttended = this.updateAttended.bind(this);
    this.renderAttendedCheckbox = this.renderAttendedCheckbox.bind(this);
    this.updateParticipantCallback = this.updateParticipantCallback.bind(this);
  }

  refreshCache() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch('/admin/ticketing/refresh_cache', {
      method: `POST`,
      redirect: "manual",
      body: {},
      headers: {
        "X-CSRF-Token": token,
      }
    }).then(response => {
      try {
        return response.json();
      }
      catch(e) {
        console.log(e);
        component.setState({
          loading: false,
          error: "An error occurred. Please try again later."
        })
      }
    }).then(json => {
      if (json.error == null) {
        location.reload();
      }
    });
  }

  updateParticipantCallback(new_participants) {
    this.setState({
      participants: new_participants
    })
  }

  colorPalatte() {
    return [
      "#1FBDEB",
    ]
  }

  updateTicket(ticket, count) {
    let { ticketTypes } = this.state;
    ticket.count = count;
    ticketTypes.filter(x=>x.id != ticket.id).concat([ticket]);
    this.setState({
      ticketTypes
    })
    fetchAPI(`/admin/ticketing/${ticket.id}`,(json)=>{
      console.log(json);
    },{
      method:'PATCH',
      body:{ticket_type: {count: count}}
    });
  }

  getRegistrantsByRegion() {
    let {participants} = this.state;
    participants = participants.filter(x=>x.email.indexOf("@jaguardesignstudio") == -1);

    const attendee_types = participants.map(x=> x['address_country']).filter((x,i,t) => t.indexOf(x) == i);

    let attendee_object = attendee_types.filter(x=>x).map(x=>
      [x,participants.filter(y=> y['address_country'] == x).length]
    )
    return attendee_object;
  }

  getTotalRegistrants() {
    return this.getFilteredRegistrants().length;
  }

  getTotalRegistrantsPath1() {
    return this.getFilteredRegistrants().filter(x=>x.registration_answers.programname == "Overnight");
  }
  getTotalRegistrantsPath3() {
    return this.getFilteredRegistrants().filter(x=>(x.registration_answers.programname||"").indexOf("Day")!=-1);
  }
  getTotalRegistrantsPath2() {
    return this.getFilteredRegistrants().filter(x=>x.registration_answers.programname == "Overnight - No Hotel");
  }

  getFilteredParticipants(opts={}) {
    const { participants } = this.state;
    return participants.filter(x=> !x.test_flag)
  }

  getFilteredRegistrants(opts={}) {
    const { participants } = this.state;
    return this.getFilteredParticipants(opts).filter(x=>x.status == "registered")
  }
  getFilteredDeclines(opts={}) {
    const { participants } = this.state;
    return this.getFilteredParticipants(opts).filter(x=>x.status == "declined")
  }

  renderBubbles() {
    const { invitees, invites_sent, surveys } = this.props;
    return (
      <div className="row">
        <div className="col-xs-12" style={{verticalAlign: "top"}}>
          <div className="sg-platform-report-circle-container">
            <div className="sg-platform-report-circle" style={{backgroundColor:"#0D274D"}}>
              <div className="sg-platform-report-circle-inner">
                <div className="sg-platform-report-circle-title">
                  Total
                  <br/>
                  Preregistered
                </div>
                <div className="sg-platform-report-circle-count">
                  {this.getFilteredParticipants().filter(x=> x.status === "preregistered").length}
                </div>
              </div>
              <a href="/admin/reports/preregistered_report" className="sg-platform-report-download-link">
                Download Report
              </a>
            </div>
          </div>
          <div className="sg-platform-report-circle-container">
            <div className="sg-platform-report-circle" style={{backgroundColor:"#0D274D"}}>
              <div className="sg-platform-report-circle-inner">
                <div className="sg-platform-report-circle-title">
                  Total
                  <br/>
                  Registrations
                </div>
                <div className="sg-platform-report-circle-count">
                  {this.getFilteredParticipants().filter(x=> x.status === "registered").length}
                </div>
              </div>
              <a href="/admin/reports/registered_report_path_3" className="sg-platform-report-download-link">
                Download Report
              </a>
            </div>
          </div>
          <div className="sg-platform-report-circle-container">
            <div className="sg-platform-report-circle" style={{backgroundColor:"#0D274D"}}>
              <div className="sg-platform-report-circle-inner">
                <div className="sg-platform-report-circle-title">
                  Total
                  <br/>
                  Declined
                </div>
                <div className="sg-platform-report-circle-count">
                  {this.getFilteredParticipants().filter(x=> x.status === "declined").length}
                </div>
              </div>
              <a href="/admin/reports/preregistered_report" className="sg-platform-report-download-link">
                Download Report
              </a>
            </div>
          </div>
          <br/>
          <br/>
        </div>
        <br/>
      </div>
    )
  }

  renderParticipants() {
    return (
      <div className="row">
        <div className="col-xs-3 col-sm-2">
          <ReportBubble
            title={"Preregistered"}
            bubbleTooltip={"# of all people pre-registered in the database"}
            count={this.getFilteredParticipants().filter(x=>x.status !="disallowed").length}
          />
        </div>
        <div className="col-xs-3 col-sm-2">
          <ReportBubble
            title={"Registered"}
            bubbleTooltip={"# of all people that registered either via broadcast email, individual send or were entered manually"}
            count={this.getFilteredParticipants().filter(x=>x.status =="registered").length}
            background="#00BCEB"
          />
        </div>
        <div className="col-xs-3 col-sm-2">
          <ReportBubble
            title={"Declined"}
            bubbleTooltip={"# of all customers that declined either via broadcast email, individual send or were entered manually"}
            count={this.getFilteredParticipants().filter(x=>x.status =="declined").length}
            background="#818181"
          />
        </div>
        <div className="col-xs-3 col-sm-6">
          <div className="sg-admin-panel-bubble-reports">
            <a href="/admin/reports/preregistered_report" target="_blank">
              <div className="sg-admin-panel-bubble-report">
                <div className="sg-admin-panel-bubble-report-circle">
                  <img src="/images/admin/file-arrow-down.png" />
                </div>
                <div className="sg-admin-panel-bubble-report-text">All Participants Report</div>
              </div>
            </a>
          </div>
          <div className="sg-admin-panel-bubble-reports">
            <a href="/admin/reports/registered_report" target="_blank">
              <div className="sg-admin-panel-bubble-report">
                <div className="sg-admin-panel-bubble-report-circle">
                  <img src="/images/admin/file-arrow-down.png" />
                </div>
                <div className="sg-admin-panel-bubble-report-text">Registration Report</div>
              </div>
            </a>
          </div>
          <div className="sg-admin-panel-bubble-reports">
            <a href="/admin/reports/declined_report" target="_blank">
              <div className="sg-admin-panel-bubble-report">
                <div className="sg-admin-panel-bubble-report-circle">
                  <img src="/images/admin/file-arrow-down.png" />
                </div>
                <div className="sg-admin-panel-bubble-report-text">Declined Report</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    )
  }

  getBubbleBackground(index) {
    const backgrounds = [
      "#0D274C",
      "#00BCEB",
      "#818181"
    ];
    return backgrounds[index%backgrounds.length];
  }

  renderSurveys() {
    const { form_submissions } = this.props;
    const renderedSurveys = form_submissions.map((form, index)=>
      <div className="col-xs-3 col-sm-2">
        <ReportBubble
          title={form.title}
          bubbleTooltip={"# of surveys taken"}
          count={form.submissions.forms_submissions.length}
          url={`/admin/reports/survey_report?gid=${form.gid}&title=${form.title}`}
          background={this.getBubbleBackground(index)}
        />
      </div>
    );
    return (
      <div className="row">
        {renderedSurveys}
      </div>
    );
  }

  renderCheckbox(value) {
    if(value) {
      return (
        <img src="/images/admin-checkmark.svg" className="sg-admin-table-checkbox" />
      )
    }
  }

  getSmallStyle() {
    return {
      width: 30,
      maxWidth: 30,
      textAlign: "center",
    }
  }

  getTableRegistrants() {
    const { invites_sent } = this.props;
    let participants = this.getFilteredParticipants();
    participants = participants.map(attendee=>{
      attendee.lower_first_name = attendee && attendee.name_first ? attendee.name_first.toLowerCase() : "";
      attendee.lower_last_name = attendee && attendee.name_last ? attendee.name_last.toLowerCase() : "";
      attendee.lower_company = attendee && attendee.company ? attendee.company.toLowerCase() : "";
      attendee.registered = true;
      attendee.attended = attendee.registration_answers.attended;
      // attendee.evaluation = surveys.map(x=> x.uuid).indexOf(attendee.uuid) != -1;
      attendee.ticket_type = attendee.registration_answers.programname;
      return attendee;
    })
    return participants;
  }

  didUserAttend(attendee) {
    return false;
  }

  getTableColumns() {
    const { user } = this.props;
    // { title: 'Evaluation', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'evaluation', sorting: true, render: (row) => this.renderCheckbox(row.evaluation)},
    // { title: 'Attended', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'attended', sorting: true, render: (row) => this.renderCheckbox(row.attended == true)},

    let allColumns = [
      { title: 'First', field: 'lower_first_name', render: (row) => row.name_first},
      { title: 'Last', field: 'lower_last_name', render: (row) => row.name_last},
      { title: 'Email', field: 'email', render: (row) => row.email},
      { title: 'Company', field: 'lower_company', render: (row) => row.company },
      { title: 'Ticket Type', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'ticket_type', sorting: true},
      { title: 'Status', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'status', sorting: true},
      { title: 'Attended Event', field: 'attended_event', render: this.renderAttendedCheckbox},
      { title: 'Registered', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'status', sorting: true, render: (row) => this.renderCheckbox(row.status == "registered")},
      { title: 'Declined', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'status', sorting: true, render: (row) => this.renderCheckbox(row.status == "declined")},
    ]
    return allColumns;
  }

  renderAttendedCheckbox(row) {
    let value = row.attended;
    return(
      <img
        src={ value === "True" ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "7px"}}
        onClick={(e) => {
          this.updateAttended(row, value === "True" ? "False" : "True")
        }}
      />
    )
  }

  updateAttended(row, value) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/admin/ticketing/update_user`, {
      method: `POST`,
      redirect: "manual",
      body: JSON.stringify({
        gid: row.gid,
        user: {
          email: row.email,
          attended: value
        }
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    }).then(response => {
      return response.json();
    }).then(json => {
      if (json.error == null) {
        this.updateParticipantCallback(json.participants)
      }
    });
  }

  renderDwellTimeReport() {
    const { googleDwellJson } = this.state;
    const tiles = [
      {name: "Dashboard", url:"/", averageTime:0},
      {name: "Agenda", url:"/agenda", averageTime:0},
      {name: "Session Catalog", url:"/pages/meetings", averageTime:0},
      {name: "Partners", url:"/partners", averageTime:0},
      {name: "Speakers", url:"/speakers", averageTime:0},
      {name: "Film Festival", url:"/film_festival", averageTime:0},
      {name: "Session Replays", url:"/session_replays", averageTime:0},
      {name: "Resources", url:"/resources", averageTime:0},
      {name: "Surveys", url:"/surveys", averageTime:0},
      {name: "FAQ", url:"/info", averageTime:0},
    ]

    if(googleDwellJson &&  googleDwellJson.rows) {
      let stats = googleDwellJson.rows
      tiles.map(tile=> {
        const row = stats.find(y=> y[0] == tile.url)
        if(row) {
          tile.averageTime = parseFloat(row[4]).toFixed(0)
        }
        return tile;
      })

      const data_mapped = tiles.map(x => [x.name, x.averageTime])
      return (
        <div className="col-xs-12">
          <h1 className="sg-platform-report-graph-title"> Average Page Dwell Times</h1>
           <ColumnChart xtitle="Page" ytitle="Average Dwell Time (seconds)" color={["#1FBDEB"]} data={data_mapped} />
        </div>
      )
    }
  }

  columnChartDataSet() {
    return {
        borderColor:"rgba(31, 189, 235, 0.5)",
        backgroundColor: "rgba(31, 189, 235, 0.5)",
        lineTension: 0,
        fill: true,
      }
  }

  columnChartDataSetTotalReg() {
    return {
      borderColor: ["rgba(124, 124, 124, 0.5)", "rgba(31, 189, 235, 0.5)"],
      backgroundColor: ["rgba(124, 124, 124, 0.5)", "rgba(31, 189, 235, 0.5)"],
      fill: true,
    }
  }

  getTotalRegistrantsChartData() {
    const registrants = this.getFilteredRegistrants()
    return [
      ["Cisco Employee + Octagon", registrants.filter(x=> x.email.indexOf("@cisco.com") != -1 ||  x.email.indexOf("@octagon.com") != -1).length],
      ["Customer Total", registrants.filter(x=> x.email.indexOf("@cisco.com") == -1 &&  x.email.indexOf("@octagon.com") == -1).length]
    ]
  }

  getTotalCustomerTitleChartData() {
    const registrants = this.getFilteredRegistrants().filter(x=> x.email.indexOf("@cisco.com") == -1 &&  x.email.indexOf("@octagon.com") == -1)
    return registrants.map(x=> x.job_title).filter((x,i,t)=>t.indexOf(x) == i).map(x=>[x, registrants.filter(y=>y.job_title == x).length]);
  }

  getOverTime() {
    const registrants = this.getFilteredRegistrants();
    const startDate = new Date("03-13-2023");
    const currentDate = new Date();

    const datesAreOnSameDay = (first, second) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();

    let iDate = startDate; //Iterator Date
    const dateObject = {}
    while(iDate < currentDate) {
      dateObject[startDate] = registrants.filter(x=> (new Date(x.registered_at) <= iDate) || datesAreOnSameDay(new Date(x.registered_at), iDate) ).length
      iDate.setDate(iDate.getDate() + 1);
    }
    return dateObject
  }

  getRegisteredOverTimeButton(opts={}) {
    return (
      <>
      <DefaultTooltip
        tooltipId={"sg-admin-panel-registered-over-time"}
      >
        Click to view line graph
      </DefaultTooltip>
      <RegisteredOverTimeModal
        participants={this.getFilteredRegistrants(opts)}
      >
        <img
          data-tip
          data-for="sg-admin-panel-registered-over-time"
          style={{height:"40px"}}
          src="/images/admin/registered-over-time.png"
        />
      </RegisteredOverTimeModal>
      </>
    )
  }

  getEmailFullReportButton(key) {
    return (
      <a href={`/admin/reports/full_activity_report_by_tag?tag=${key}`} target="_blank">
       <img src="/images/admin/full-save-report.png" style={
         {
           height: "42px",
           marginTop: "-20px"
         }
       }/>
      </a>
    )
  }

  renderHeaderButtons() {
    const { form, user_email } = this.props;
    const { ticketTypes } = this.state;
    return (
      <div className="sg-admin-panel-header-block">
        <div className="sg-admin-openable-panel-header-custom-button" style={{float: "none", textAlign: "right", marginBottom: "20px"}}>
          <button class="sg-admin-openable-panel-header-custom-button-button" onClick={() => {location.href = '/admin/ticketing'}}>
            <img src="/images/admin/ico-checkin.svg"/>
            <div>Check in Panel</div>
          </button>
        </div>
        <div className="sg-admin-openable-panel-header-custom-button" style={{float: "none", textAlign: "right", marginBottom: "20px"}}>
          <button className="sg-admin-openable-panel-header-custom-button-button custom-button-grey" onClick={this.refreshCache}>
            <img src="/images/admin/ico-refresh.svg"/>
            <div>Refresh Cache</div>
          </button>
        </div>
        <div className="sg-admin-openable-panel-header-custom-button" style={{float: "none", textAlign: "right", marginBottom: "20px"}}>
          <AdminParticipantModal
            participants={this.getFilteredParticipants()}
            outerStyle={{display:"inline-block"}}
            ticketTypes={ticketTypes}
            form={form}
            updateParticipantCallback={this.updateParticipantCallback}
            user_email={user_email}
          >
            <button className="sg-admin-openable-panel-header-custom-button-button custom-button-blue">
              <img src="/images/admin/ico-add.svg"/>
              <div>Manual Registration</div>
            </button>
          </AdminParticipantModal>
        </div>
      </div>
    );
  }

  renderFrame() {
    const { emails, form, user_email, flipflops } = this.props;
    const { ticketTypes } = this.state;
    return (
      <div className="sg-admin-panel-container">
        {this.renderHeaderButtons()}
        <AdminOpenablePanel
          header="Participants"
          defaultOpen={true}
          forceOpen={true}
        >
          {this.renderParticipants()}
        </AdminOpenablePanel>
        <AdminOpenablePanel
          header="Tickets allocated per day"
          defaultOpen={true}
        >
          <TicketsDashboard
            participants={this.getFilteredParticipants()}
            ticketTypes={ticketTypes}
            updateTicket={this.updateTicket}
          />
        </AdminOpenablePanel>

        <AdminOpenablePanel
          header="Surveys"
          defaultOpen={true}
        >
          {this.renderSurveys()}
        </AdminOpenablePanel>

        <AdminOpenablePanel
          header="Tickets allocated per registrant"
          defaultOpen={true}
        >
          <ParticipantTicketTable
            participants={this.getFilteredParticipants()}
            ticketTypes={ticketTypes}
            form={form}
            updateParticipantCount={this.updateParticipantCount}
            updateParticipantCallback={this.updateParticipantCallback}
            user_email={user_email}
          />
        </AdminOpenablePanel>
        <AdminOpenablePanel
          header="Email Statistics"
          defaultOpen={true}
        >
          <EmailSection
            email={emails}
            emailList={[{id:'invite', name: 'Invite'}, {id: 'nonresponder', name:'Nonresponder'}, {id: 'kbyg', name:'KBYG'}]}
            supressRegistration={true}
            registrants={[]}
            declines={[]}
          />
        </AdminOpenablePanel>
        <AdminOpenablePanel
          header="Custom Reports"
          defaultOpen={true}
        >
          <CustomReportTable

          />
        </AdminOpenablePanel>
        <AdminOpenablePanel
          header="Features"
          defaultOpen={true}
          forceOpen={false}
        >
          <FlipFlopFeatures flipflops={flipflops} />
        </AdminOpenablePanel>
        <AdminOpenablePanel
          header="Admins"
          defaultOpen={false}
        >
          <AddAdminSection user_email={this.props.user_email} />
        </AdminOpenablePanel>
      </div>
      
    );

  }

  render() {
    return this.renderFrame();
  }
}

export default UsersDashboard;
