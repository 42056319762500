import React from "react";
import Table from "rc-table";
import AdminOpenablePanel from "../../platform_reports/AdminOpenablePanel"
import ReportBubble from "../../platform_reports/ReportBubble"
import GeneralTable from "../../GeneralTable"
import TicketingCheckInModal from "./TicketingCheckInModal"
import { getMetadataValue } from "../../../utility/registration/RegFormUtility";
import Cookies from "universal-cookie";

class TicketingCheckInDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      participants: props.participants,
      ticketTypes: props.ticket_types
    }
    this.renderActions = this.renderActions.bind(this);
    this.updateParticipantCallback = this.updateParticipantCallback.bind(this);
  }

  componentDidMount() {
    const interval = setInterval(() => {
      this.fetchData();
    }, 60000);
    this.setState({ interval: interval });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval)
  }

  fetchData() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch('/admin/get_tickets_and_participants', {
      method: 'GET',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        this.setState({
          participants: json.participants,
          ticketTypes: json.ticket_types
        })
      }
    });

  }

  getFilteredParticipants() {
    const { filterParticipants, participants } = this.state;
    return participants.filter(x=>!x.test_flag)
  }

  getFilteredRegistrants() {
    return this.getFilteredParticipants().filter(x=>x.status == "registered")
  }

  renderTicketStats(ticket_type, participants) {
    const totalTicketsAllocated = participants.map(x=> {
      let count = parseInt(getMetadataValue(ticket_type.slug, x["metadata"]));
      if (typeof count === "number" && !isNaN(count)) {
        return count;
      }
      return 0;
    }).reduce((a,b)=>(a||0)+(b||0), 0)

    const totalTicketsRecieved = participants.map(x=> {
      let count = parseInt(getMetadataValue(`${ticket_type.slug}transferred`, x["metadata"]));
      if (typeof count === "number" && !isNaN(count)) {
        return count;
      }
      return 0;
    }).reduce((a,b)=>(a||0)+(b||0), 0)

    return (
      <div className="ticket-stats-block">
        <div className="ticket-stats-block-name">{ticket_type.name}</div>
        <div className="ticket-stats-block-count">
          {`${totalTicketsAllocated}/${ticket_type.count}`}
        </div>
        <div className="ticket-stats-block-count">
          {totalTicketsRecieved}
        </div>
      </div>
    );
  }

  renderTicketsStats() {
    const { ticketTypes } = this.state;
    let participants = this.getFilteredRegistrants();

    const dayTickets = ticketTypes.map(ticket_type =>
      this.renderTicketStats(ticket_type, participants)
    );

    const totalTicketsAllocated = ticketTypes.map(ticket => 
      participants.map(x=> {
        let count = parseInt(getMetadataValue(ticket.slug, x["metadata"]));
        if (typeof count === "number" && !isNaN(count)) {
          return count;
        }
        return 0;
      }).reduce((a,b)=>(a||0)+(b||0), 0)
    ).reduce((a,b)=>(a||0)+(b||0), 0);

    const totalTicketsRecieved = ticketTypes.map(ticket => 
      participants.map(x=> {
        let count = parseInt(getMetadataValue(`${ticket.slug}transferred`, x["metadata"]));
        if (typeof count === "number" && !isNaN(count)) {
          return count;
        }
        return 0;
      }).reduce((a,b)=>(a||0)+(b||0), 0)
    ).reduce((a,b)=>(a||0)+(b||0), 0);

    const totalTicketsAvailable = ticketTypes.map(ticket => 
      ticket.count
    ).reduce((a,b)=>(a||0)+(b||0), 0);

    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="ticket-stats-wrapper">
            <div className="ticket-stats-block ticket-stats-block-desc">
              <div className="ticket-stats-block-name">&nbsp;</div>
              <div className="ticket-stats-block-count">Tickets allocated/available</div>
              <div className="ticket-stats-block-count">Tickets transferred</div>
            </div>            
            {dayTickets}
            <div className="ticket-stats-block ticket-stats-block-all">
              <div className="ticket-stats-block-name">Event Total</div>
              <div className="ticket-stats-block-count">
                {`${totalTicketsAllocated}/${totalTicketsAvailable}`}
              </div>
              <div className="ticket-stats-block-count">
                {totalTicketsRecieved}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderGuests(row) {
    return [
      getMetadataValue('guestname', row["metadata"]),
      getMetadataValue('guestname2', row["metadata"]),
      getMetadataValue('guestname3', row["metadata"])
    ].filter(el =>
       el !== undefined && el !== ''
    ).join(', ');
  }

  getTableColumns() {
    const { ticketTypes } = this.state;
    let allColumns = [
      {title: 'Name', field: 'lower_full_name', render: (row) => row.full_name},
      {title: "Email", field: "email"},
      {title: "Guest Name", field: "guestname", render: (row) => this.renderGuests(row), customFilterAndSearch: (value, row) => {
        return this.renderGuests(row).toLowerCase().includes(value.toLowerCase());
      }}
    ]
    
    let days = {
      "Thursday": <>THU<br />Feb 1</>,
      "Friday": <>FRI<br />Feb 2</>,
      "Saturday": <>SAT<br />Feb 3</>,
      "Sunday": <>SUN<br />Feb 4</>,
      "ThursdayHotel": <>THU<br />Hotel</>,
      "FridayHotel": <>Fri<br />Hotel</>,
      "SaturdayHotel": <>Sat<br />Hotel</>,
      "SundayHotel": <>Sun<br />Hotel</>,
    }
    allColumns = allColumns.concat(ticketTypes.map(ticketType => {
      let ticketAbbr = '';
      if (ticketType.name.includes("Thursday")) {
        ticketAbbr = days["Thursday"]
      } else if (ticketType.name.includes("Friday")) {
        ticketAbbr = days["Friday"]
      } else if (ticketType.name.includes("Saturday")) {
        ticketAbbr = days["Saturday"]
      } else if (ticketType.name.includes("Sunday")) {
        ticketAbbr = days["Sunday"] 
      }
      return {
        title: ticketAbbr,
        field: ticketType.slug,
        render: (row) => (
          <>
            {getMetadataValue(ticketType.slug, row["metadata"])||0}
          </>
        )
      }
    }));

    let checkinDays = [
      'hotelsuitecheckinday1',
      'hotelsuitecheckinday2',
      'hotelsuitecheckinday3',
      'hotelsuitecheckinday4'
    ]
    allColumns = allColumns.concat(checkinDays.map(checkinDay => {
      let colTitle = '';
      if (checkinDay.includes("day1")) {
        colTitle = days["ThursdayHotel"];
      } else if (checkinDay.includes("day2")) {
        colTitle = days["FridayHotel"];
      } else if (checkinDay.includes("day3")) {
        colTitle = days["SaturdayHotel"];
      } else if (checkinDay.includes("day4")) {
        colTitle = days["SundayHotel"];
      }
      return {
        title: colTitle,
        field: checkinDay,
        render: (row) => (
          <>
            {JSON.parse(getMetadataValue('checkinoptions', row["metadata"]))[checkinDay]||'False'}
          </>
        )
      }
    }))

    allColumns = allColumns.concat([
      { title: 'Actions', field: 'actions', render: (row) => this.renderActions(row) },
    ])

    return allColumns;
  }

  updateParticipantCallback(new_participants) {
    this.setState({
      participants: new_participants
    })
  }

  renderActions(row) {
    const { ticketTypes } = this.state;
    return (
      <>
        <TicketingCheckInModal
          participant={row}
          ticketTypes={ticketTypes}
          // fields={fields}
          updateParticipantCallback={this.updateParticipantCallback}
        >
          <a href="#">Edit</a>
        </TicketingCheckInModal>
      </>
    )
  }

  getTablePeople() {
    const registrants = this.getFilteredRegistrants();
    const mainRegistrants = registrants.map(x=>{
        x.lower_name_first = x.name_first.toLowerCase(),
        x.lower_name_last = x.name_last.toLowerCase(),
        x.full_name = `${x.name_first} ${x.name_last}`
        x.guest_name = `${x.registration_answers.guestfirstname||""} ${x.registration_answers.guestlastname||""}`
        x.lower_guest_name = `${x.registration_answers.guestfirstname||""} ${x.registration_answers.guestlastname||""}`.toLowerCase()
        x.lower_full_name = `${x.name_first} ${x.name_last}`.toLowerCase()
        x.has_guest = x.registration_answers.bringingguest
        return x
    })
    return mainRegistrants;
  }

  render() {
    const { surveys,emails, fields} = this.props;
    return (
      <div className="sg-admin-panel-container">
        <div className="sg-admin-openable-panel-header-custom-button" style={{float: "none", textAlign: "right", marginBottom: "20px"}}>
          <button className="sg-admin-openable-panel-header-custom-button-button" onClick={() => {location.href = '/admin'}}> Back to Admin Panel </button>
        </div>
        <AdminOpenablePanel
          header="Ticket Stats"
          defaultOpen={true}
        >
          {/* {this.renderStats()} */}
          {this.renderTicketsStats()}
        </AdminOpenablePanel>
        <AdminOpenablePanel
          header="Participants"
          defaultOpen={true}
        >
          <GeneralTable
            columns={this.getTableColumns()}
            data={this.getTablePeople()}
          />
        </AdminOpenablePanel>
      </div>
    )
  }
}

export default TicketingCheckInDashboard;
