import React from "react";
import PropTypes from "prop-types";

class CustomReportTable extends React.Component {
  constructor(props) {
    super(props);
  }

  getReports(){
    return [
      // {name:"Employee Registration", url:"/admin/reports/employee_report"},
      {name:"Hotel Report", url:"/admin/reports/hotel_report"},
      {name:"Exec Registration Report", url:"/admin/reports/exec_registration_report"},
      {name:"Ticketing Report", url:"/admin/reports/ticketing_report"},
      {name:"Am Ticket Requests", url: "/admin/reports/all_am_requests_report"},
      {name:"Cancelled Report", url: "/admin/reports/cancelled_report"},
      {name:"Combined Customers Survey Report", url: "/admin/reports/combined_customers_survey_report"},
      {name:"Combined AMs Survey Report", url: "/admin/reports/combined_ams_survey_report"}

      // {name:"Visa Report", url:"/admin/reports/visa_report"},
      // {name:"Dietary Report", url:"/admin/reports/dietary_report"},
      // {name:"Interests Report", url:"/admin/reports/interests_report"},
      // {name:"Bounce Report", url:"/admin/reports/postmark_bounce_report"},
      // {name: "Nomination Snapshot Report", url:"/admin/nomination_snapshot"},
      // {name:"Registration Aborts", url: "/admin/reports/registration_aborts"},
    ]
  }

  renderReports() {
    return this.getReports().map(x=>
      this.renderReport(x)
    );
  }

  renderReport(report) {
    return (
      <div className="col-xs-12 col-sm-6">
        <div className="sg-admin-panel-bubble-reports">
          <a href={report.url} target="_blank" className="sg-admin-custom-report-button">
            <div className="sg-admin-panel-bubble-report">
              <div className="sg-admin-panel-bubble-report-circle">
                <img src="/images/admin/file-arrow-down.png" />
              </div>
              <div className="sg-admin-panel-bubble-report-text">{report.name}</div>
            </div>
          </a>
        </div>
      </div>
    )

    return (
      <div className="sg-admin-custom-report-container col-xs-12 col-sm-6">
        <a
          href={report.url}
          target="_blank"
          className="sg-admin-custom-report-button"
        >
          <img src="/images/admin/custom-report-download.png"/>
        </a>
        <div className="sg-admin-custom-report-name">
          <a href={report.url} target="_blank">
            {report.name}
          </a>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="sg-admin-custom-report-table">
        {this.renderReports()}
        <div style={{clear: "both"}} />
      </div>
    )
  }

}

export default CustomReportTable;
